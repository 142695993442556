'use client';

import { useState, useEffect, useContext } from 'react';
import { getCADTool } from '@future-joinery-systems/fjs-cad';
import { Spinner } from 'fjs-components';
import { useRouter } from 'next/dist/client/router';
import RegisterModal from './RegisterModal';
import PasswordResetModal from './PasswordResetModal';
import NewPasswordModal from './NewPasswordModal';
import useUser from '../../lib/useUser';
import Form from './LoginForm';
import fetchJson from '../../lib/fetchJson';
import AuthTokenStore from '../../lib/authTokenStore';
import {
  setInSketchupStorage,
  getUserObjectFromSketchupStorage,
  getAdminFlagFromSketchupStorage, getSubscriptionFromSketchupStorage,
} from '../../lib/sketchupStorage';
import UserContext from '../helpers/contexts/UserContext';

interface User { email:string, password:string }

function Login() {
  console.log('LOGIN');
  const { mutateUser } = useUser({
    redirectTo: '',
    redirectIfFound: false,
  });
  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState('');
  const [failureMsg, setFailureMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(!!router?.query?.token);
  const [signInLoading, setSignInLoading] = useState(false);
  const cadTool = typeof window !== 'undefined' ? getCADTool() : null;
  const userContext = useContext(UserContext);

  if (userContext.userLoggedIn.isLoaded && userContext.userLoggedIn.isLoggedIn) {
    router.push('/products');
  }

  useEffect(() => {
    if (router.query.token) {
      setShowNewPasswordModal(true);
    }
  }, [router.query.token]);

  async function setUserDataInSketchUp(res) {
    setInSketchupStorage('token', res.login);
    setInSketchupStorage('userId', res.userId);
    setInSketchupStorage('isAdmin', res.isAdmin);
    setInSketchupStorage('subscription', res.subscription);
    const resObj = JSON.stringify({ ...res }).replace(/"/g, '\\"');
    await setInSketchupStorage('user', resObj);
    userContext.setUserLoggedIn({
      isLoggedIn: res.isLoggedIn, isAdmin: res.isAdmin, isLoaded: true, subscription: res.subscription,
    });
  }

  async function handleSubmit(e) {
    setSignInLoading(true);
    e.preventDefault();
    const body : User = {
      email: e.currentTarget.email.value,
      password: e.currentTarget.password.value,
    };

    if (cadTool) {
      try {
        const resp = await fetchJson('/api/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        });
        if (!resp.isLoggedIn) {
          setSignInLoading(false);
          setFailureMsg('Login failed: Incorrect username or password');
        } else {
          await setUserDataInSketchUp(resp);
          router.push('/products');
        }
      } catch (error) {
        setSignInLoading(false);
        console.error('An unexpected error happened:', error);
        setErrorMsg(error.data.message);
      }
    } else {
      try {
        const resp = await mutateUser(
          fetchJson('/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
          }),
        );
        if (!resp.isLoggedIn) {
          setSignInLoading(false);
          setFailureMsg('Login failed: Incorrect username or password');
        } else {
          AuthTokenStore.save(resp.login);
        }
      } catch (error) {
        setSignInLoading(false);
        console.error('An unexpected error happened:', error);
        setErrorMsg(error.data.message);
      }
    }
  }

  async function redirectIfSkpLoggedIn() {
    const user = await getUserObjectFromSketchupStorage();
    if (user) {
      const isAdmin = await getAdminFlagFromSketchupStorage();
      const subscription = await getSubscriptionFromSketchupStorage();
      userContext.setUserLoggedIn({
        isLoggedIn: !!user, isAdmin: isAdmin === 'true', isLoaded: true, subscription,
      });
      router.push('/products');
    } else {
      userContext.setUserLoggedIn({
        isLoggedIn: false, isAdmin: false, isLoaded: true, subscription: 'Inactive',
      });
    }
  }

  async function handleRegisterSubmit(e) {
    e.preventDefault();
    const body = {
      firstName: e.currentTarget.firstName.value,
      lastName: e.currentTarget.lastName.value,
      email: e.currentTarget.email.value.toLowerCase(),
      password: e.currentTarget.password.value,
    };

    try {
      const registerResp = await mutateUser(
        fetchJson('/api/signup', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        }),
      );
      console.log('SIGNED UP');
      setShowRegisterModal(false);
      const loginResp = await mutateUser(
        fetchJson('/api/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        }),
      );

      if (!loginResp.isLoggedIn) {
        setFailureMsg('Login failed: try closing the plugin window and logging in again');
      } else {
        await setUserDataInSketchUp(loginResp);
        router.push('/products');
      }
    } catch (error) {
      console.error('An unexpected error happened:', error);
      setErrorMsg(error.data.message);
      if (error.data.message.includes('Account already exists')) {
        setShowRegisterModal(false);
      }
    }
  }

  async function handlePasswordResetSubmit(e) {
    e.preventDefault();
    const body = {
      email: e.currentTarget.email.value.toLowerCase(),
    };

    try {
      const resetResp = await fetchJson('/api/passwordReset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      const loginResp = await mutateUser(
        resetResp,
      );
      console.log('PASSWORD RESET', loginResp, resetResp);
      setShowPasswordResetModal(false);
      setSuccessMsg(resetResp.message);
    } catch (error) {
      console.error('An unexpected error happened:', error);
      setErrorMsg(error.data.message);
    }
  }

  async function handleNewPasswordSubmit(e) {
    e.preventDefault();
    const body = {
      email: router.query.email,
      newPassword: e.currentTarget.password.value,
      token: router.query.token,
    };

    try {
      const resetResp = await fetchJson('/api/newPassword', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      if (resetResp.success) {
        console.log('NEW PASSWORD SET', resetResp);
        setShowNewPasswordModal(false);
        setSuccessMsg(resetResp.message);
      } else {
        setErrorMsg(resetResp.message);
      }
    } catch (error) {
      console.error('An unexpected error happened:', error);
      setErrorMsg(error.data.message);
    }
  }

  useEffect(() => {
    if (cadTool) {
      redirectIfSkpLoggedIn();
    }
  }, []);

  if (userContext && !userContext.userLoggedIn.isLoaded) {
    return (
      <div className="flex h-screen justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="h-14" />
        <div className="flex-grow text-center flex flex-col items-center justify-center">
          <h1 className="font-light leading-tight max-w-lg">
            Welcome to the
            <br />
            FJS Configurator.
          </h1>
        </div>
        <div className="container mx-auto px-4 pt-6 pb-12 flex-shrink-0">
          <Form
            successMsg={successMsg}
            errorMessage={errorMsg}
            onSubmit={handleSubmit}
            failureMsg={failureMsg}
            loading={signInLoading}
          />
          <div className="mt-6 text-sm text-center w-64 mx-auto">
            {'Don\'t have a login yet? Sign up '}
            <span role="button" tabIndex={0} className="transition text-fjsMidBlue hover:cursor-pointer hover:text-fjsBlue hover:underline" onClick={() => setShowRegisterModal(true)}>here</span>
            .
          </div>
          <div className="mt-3 text-sm text-center w-64 mx-auto">
            {'Forgotten password? Reset it '}
            <span role="button" tabIndex={0} className="transition text-fjsMidBlue hover:cursor-pointer hover:text-fjsBlue hover:underline" onClick={() => setShowPasswordResetModal(true)}>here</span>
            .
          </div>
        </div>
        <div className="h-9" />
      </div>
      <RegisterModal show={showRegisterModal} setShow={setShowRegisterModal} errorMsg={errorMsg} handleSubmit={handleRegisterSubmit} failureMsg={failureMsg} />
      <PasswordResetModal show={showPasswordResetModal} setShow={setShowPasswordResetModal} errorMsg={errorMsg} handleSubmit={handlePasswordResetSubmit} failureMsg={failureMsg} />
      <NewPasswordModal show={showNewPasswordModal} setShow={setShowNewPasswordModal} errorMsg={errorMsg} handleSubmit={handleNewPasswordSubmit} failureMsg={failureMsg} />
    </>
  );
}

export default Login;
