import PropTypes from 'prop-types';
import { LoadingIcon } from '../Buttons';

const Form = ({
  isLogin, actionText, errorMessage, successMsg, onSubmit, failureMsg, loading,
}) => (
  <form className="mx-auto max-w-sm flex flex-col items-center" onSubmit={onSubmit}>
    {!!errorMessage && <p className="px-4 py-3 mb-3 rounded-md text-fjsWhite text-center bg-fjsRed">{errorMessage}</p>}
    {failureMsg && <p className="px-4 py-3 mb-3 rounded-md text-fjsWhite text-center bg-fjsRed">{failureMsg}</p>}
    {successMsg && <p className="px-4 py-3 mb-3 rounded-md text-fjsWhite text-center bg-fjsGreen">{successMsg}</p>}
    {!isLogin && (
    <>
      <div className="mb-4 w-full">
        <label className="text-xs">
          First Name:
          <input
            className="text-base mt-2 input-white"
            autoComplete="given-name"
            type="text"
            name="firstName"
            required
          />
        </label>
      </div>
      <div className="mb-4 w-full">
        <label className="text-xs">
          Last Name:
          <input
            className="text-base mt-2 input-white"
            autoComplete="family-name"
            type="text"
            name="lastName"
            required
          />
        </label>
      </div>
    </>
    )}
    <div className="mb-4 w-full">
      <label className="text-xs">
        Email:
        <input
          className="text-base mt-2 input-white"
          autoComplete="email"
          type="text"
          name="email"
          required
        />
      </label>
    </div>
    <div className="mb-4 w-full">
      <label className="text-xs">
        Password:
        <input
          className="text-base mt-2 input-white"
          autoComplete="password"
          type="password"
          name="password"
          required
        />
      </label>
    </div>
    <button
      type="submit"
      className="mt-4 button-green w-24 text-center flex items-center justify-center"
      disabled={loading}
    >
      {loading ? (
        <LoadingIcon />
      ) : actionText}
    </button>
  </form>
);

export default Form;

Form.propTypes = {
  isLogin: PropTypes.bool,
  actionText: PropTypes.string,
  errorMessage: PropTypes.string,
  failureMsg: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

Form.defaultProps = {
  isLogin: true,
  actionText: 'Sign In',
  // errorMessage: 'An error has occurred',
  // failureMsg: 'Submission failed',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: () => {},
  loading: false,
};
