import { Modal } from 'fjs-components';
import Form from './LoginForm';

const RegisterModal = ({
  show, setShow, errorMsg, handleSubmit, failureMsg,
}) => (
  <Modal hasCloseButton show={show} setShow={setShow}>
    <div className="w-[24rem]">
      <h2 className="mb-6 text-center">Sign Up to Get Started</h2>
      <Form isLogin={false} actionText="Sign Up" errorMessage={errorMsg} onSubmit={handleSubmit} failureMsg={failureMsg} />
    </div>
  </Modal>
);

export default RegisterModal;
