'use client';

// import dynamic from 'next/dynamic';
import Login from '../components/login/Login';

// const LoginWithNoSSR = dynamic(() => import('../components/login/Login'), {
//   ssr: false,
// });

export const dynamic = 'force-dynamic';

const LoginPage = () => {
  console.log('LoginPage');
  return (
    <Login />
  );
};

export default LoginPage;
