import { Modal } from 'fjs-components';
import { useState } from 'react';

const NewPasswordModal = ({
  show, setShow, errorMsg, handleSubmit, failureMsg,
}) => {
  const [canSave, setCanSave] = useState(false);
  const [warning, setWarning] = useState('');
  const checkPassword = (e) => {
    const password = e.target.form.password.value;
    const confirmPassword = e.target.form.confirmPassword.value;
    if (password.length > 7 && password === confirmPassword) {
      setCanSave(true);
      setWarning('');
    } else if (password.length <= 7) {
      setCanSave(false);
      setWarning('Passwords must have 8 or more characters');
    } else {
      setCanSave(false);
      if (password.length && confirmPassword.length) {
        setWarning('Passwords must match');
      }
    }
  };

  return (
    <Modal hasCloseButton show={show} setShow={setShow}>
      <div className="w-[24rem]">
        <h2 className="mb-6 text-center">Reset your password</h2>
        <form className="mx-auto max-w-sm flex flex-col items-center" onSubmit={handleSubmit}>
          {!!errorMsg && <p className="px-4 py-2 mb-3 rounded-md text-fjsWhite bg-fjsRed">{errorMsg}</p>}
          {warning && <p className="px-4 py-2 mb-3 rounded-md bg-fjsMidYellow">{warning}</p>}
          <p className="mb-3 text-xs">Please enter a new password with 8 or more characters</p>
          <div className="mb-4 w-full">
            <label className="text-xs">
              New Password:
              <input className="text-base mt-2 input-white" type="password" onChange={checkPassword} name="password" required />
            </label>
            <label className="text-xs">
              Confirm Password:
              <input className="text-base mt-2 input-white" type="password" onChange={checkPassword} name="confirmPassword" required />
            </label>
          </div>
          <button type="submit" className="mt-4 mx-auto button-green" disabled={!canSave}>Save new password</button>
        </form>
      </div>
    </Modal>
  );
};

export default NewPasswordModal;
