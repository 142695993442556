import { Modal } from 'fjs-components';
import Form from './LoginForm';
import useAutoFocus from '../hooks/useAutoFocus';

const PasswordResetModal = ({
  show, setShow, errorMsg, handleSubmit, failureMsg,
}) => {
  const emailInput = useAutoFocus();
  return (
    <Modal hasCloseButton show={show} setShow={setShow}>
      <div className="w-[24rem]">
        <h2 className="mb-6 text-center">Reset your password</h2>
        {!!errorMsg && <p className="px-4 py-2 mb-3 rounded-md text-fjsWhite bg-fjsRed">{errorMsg}</p>}
        <form className="mx-auto max-w-sm flex flex-col items-center" onSubmit={handleSubmit}>
          <div className="mb-4 w-full">
            <label className="text-xs">
              Email:
              <input className="text-base mt-2 input-white" ref={emailInput} autoComplete="email" type="text" name="email" required />
            </label>
          </div>
          <button type="submit" className="mt-4 mx-auto button-green">Send reset email</button>
        </form>
      </div>
    </Modal>
  );
};

export default PasswordResetModal;
